<template>
  <section class="jci_course_apply container970">
    <div class="top-banner">
      <el-image :src="skillCourseInfo.cover" fit="cover" style="width: 100%; height: 100%" class="rounded" alt="">
        <div slot="error" class="image-slot">
          <img class="rounded" src="../../assets/images/donation/second_bg.png" alt="" />
        </div>
      </el-image>
      <div>
        <h3>{{ skillCourseInfo.course }}</h3>
      </div>
    </div>

    <section style="display: flex; justify-content: flex-end">
      <span v-if="skillCourseInfo.is_has_certificate" style="color: #EDBE38;">You are a trainer for this course.</span>
      <div class="to_my_training" v-else>
        <span @click="openApply">Apply as a Trainer</span>
      </div>
    </section>

    <el-card class="table-card mt-3" style="padding-top:20px">
      <h4 class="mb-3">Course Details</h4>
      <section class="basic_info">
        <div class="item d-flex mb-2">
          <span class="left">Hours</span>
          <div class="right">{{skillCourseInfo.duration}}</div>
        </div>
        <div class="item d-flex mb-2">
          <span class="left">Number of Participants</span>
          <div class="right">{{skillCourseInfo.min_number}}-{{skillCourseInfo.max_number}}</div>
        </div>
        <div class="item d-flex mb-2 description">
          <span class="left">Description</span>
          <div class="right" v-html="skillCourseInfo.description"></div>
        </div>
        <div class="item d-flex mb-2 description">
          <span class="left">Objectives</span>
          <div class="right" v-html="skillCourseInfo.objective"></div>
        </div>
        <div class="item d-flex mb-2">
          <span class="left">Language</span>
          <div class="right" v-html="skillCourseInfo.language"></div>
        </div>
        <div class="item d-flex mb-2">
          <span class="left">Equipment Requirements</span>
          <div class="right"
            v-if="skillCourseInfo.computer||skillCourseInfo.projector||skillCourseInfo.screen||skillCourseInfo.flip_chart">
            <span v-if="skillCourseInfo.computer">Computer, </span>
            <span v-if="skillCourseInfo.projector">Projector, </span>
            <span v-if="skillCourseInfo.screen">Screen, </span>
            <span v-if="skillCourseInfo.flip_chart">Flip Chart</span>
          </div>
          <div class="right" v-else>-</div>
        </div>
      </section>
      <el-divider></el-divider>
      <h4 class="mb-3">Prerequisites</h4>
      <section class="basic_info">
        <div class="item d-flex mb-2">
          <span class="left" :class="skillCourseInfo.course_prerequisites.length?'mt-1':''">Course Prerequisite</span>
          <div class="right" v-if="skillCourseInfo.course_prerequisites.length">
            <el-tag type="info" class="mr-2 mb-2" v-for="(v, i) in skillCourseInfo.course_prerequisites" :key="i">
              <span>{{v.course}}, </span>
              <span v-if="v.year==0">In the last 0 years</span>
              <span v-else>Within {{v.year}} year</span>
            </el-tag>
          </div>
          <span v-else>-</span>
        </div>
        <div class="item d-flex mb-2">
          <span class="left" >Trainer Prerequisite</span>
          <div class="right">
            <el-tag class="mr-2 mb-2">
              <span>{{skillCourseInfo.course}}, </span>
              <span v-if="skillCourseInfo.trainer_completed_year==0">In the last 0 years</span>
              <span v-else>Within {{skillCourseInfo.trainer_completed_year}} year</span>
            </el-tag>
            <el-tag type="info" class="mr-2 mb-2" v-for="(v, i) in skillCourseInfo.trainer_prerequisites" :key="i">
              <span>{{v.course}}, </span>
              <span v-if="v.year==0">In the last 0 years</span>
              <span v-else>Within {{v.year}} year</span>
            </el-tag>
          </div>
        </div>
      </section>
    </el-card>

    <el-card class="table-card mt-4 training_card">
      <section class="top_container">
        <el-row class="p-0" :gutter="10">
          <el-col :xs="24" :sm="24" :md="12">
            <div class="search">
              <el-input placeholder="Training Name" v-model="keyword" @keyup.enter.native="getMyTrainingSearch"
                @clear="getMyTrainingSearch" clearable style="width: 173px;" class="mr-2">
                <i slot="suffix" class="el-input__icon el-icon-search cursor-pointer" @click="getMyTrainingSearch"></i>
              </el-input>
              <el-select placeholder="Status" v-model="status" clearable @change="getMyTrainingSearch"
                style="width: 173px;">
                <el-option label="Registration Open" :value="2"></el-option>
                <el-option label="Training Started" :value="3"></el-option>
                <el-option label="Training Closed" :value="4"></el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12">
            <div class="circle_content d-flex align-items-center justify-content-end">
              <div class="circle_item">
                <i class="circle" style="background: #53c5b8;"></i>
                <span>Registration Open</span>
              </div>
              <div class="circle_item">
                <i class="circle" style="background: #FFBA68;"></i>
                <span>Training Started</span>
              </div>
              <div class="circle_item">
                <i class="circle" style="background: #999999;"></i>
                <span>Training Closed</span>
              </div>
            </div>
          </el-col>
        </el-row>
      </section>

      <div class="training_container" v-if="courseTrainingList.length">
        <div class="d-flex flex-wrap height_scroll">
          <div class="small_card" v-for="v of courseTrainingList" :key="v.id"
            @click="$router.push(`/training_detail?id=${v.training_id}`)">
            <section class="training_content d-flex align-items-center justify-content-between">
              <h5 class="training_title" :title="v.training">{{v.training}}</h5>
              <i class="circle" :style="{background:v.status==2?'#53c5b8':v.status==3?'#FFBA68':'#999999'}"></i>
            </section>
            <section class="bottom mt-2">
              <span class="left">Trainer: </span>
              <span class="one_line" :title="v.trainer">{{ v.trainer }}</span>
            </section>
            <section class="bottom">
              <span class="left">Language: </span>
              <span>{{ v.language }}</span>
            </section>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-center" style="background:#fff;margin: 60px 0" v-else>
        <img style="width:250px;" src="../../assets/images/group/empty1.png" alt="" />
      </div>

      <div class="d-flex justify-content-end container mb-3">
        <el-pagination background layout="prev, pager, next" :page-size="12" @current-change="handleCurrentChange"
          :current-page.sync="page" :total="total" hide-on-single-page>
        </el-pagination>
      </div>
    </el-card>

    <!-- 讲师进度列表 -->
    <el-dialog title="Apply as a Trainer" :visible.sync="trainerApply" width="800px">
      <!-- Apply as Trainer -->
      <section v-if="is_next">
        <h5>Prerequisites</h5>
        <el-table :data="trainerList" style="width: 100%" tooltip-effect="light" :show-header="false"
          :row-style="handlerStyle" max-height="500px">
          <el-table-column prop="course" label="Course" min-width="100" show-overflow-tooltip></el-table-column>
          <el-table-column prop="condition" label="Condition" min-width="110" show-overflow-tooltip></el-table-column>
          <el-table-column prop="time" label="Time" min-width="150">
            <template slot-scope="scope">
              <span v-if="scope.row.time == 0">In the last 0 years</span>
              <span v-else>Within {{scope.row.time}} year</span>
            </template>
          </el-table-column>
          <el-table-column prop="is_meet" min-width="100" label="Time">
            <template slot-scope="scope">
              <span>{{scope.row.is_meet?'Effective':'Ineffective'}}</span>
            </template>
          </el-table-column>
        </el-table>
        <div class="d-flex justify-content-end mt-5 next_apply_btn">
          <el-button type="primary" :disabled="!disabled" @click="handlerNext">Next</el-button>
        </div>
      </section>

      <!-- Propose A Course -->
      <section class="propose_course" v-else>
        <el-form label-position="top" label-width="80px" :model="formLabelAlign">
          <el-form-item label="Name">
            <el-input v-model="formLabelAlign.trainer"></el-input>
          </el-form-item>
          <el-form-item label="National Organization">
            <el-select placeholder="Please select" v-model="organizationData" clearable value-key="id"
              style="width: 100%;">
              <el-option v-for="v in organizationList" :key="v.id" :label="v.name" :value="v"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Country">
            <el-select placeholder="Please select" v-model="countryData" clearable value-key="id" style="width: 100%;">
              <el-option v-for="v in countryList" :key="v.id" :label="v.name" :value="v"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Gender">
            <el-select v-model="formLabelAlign.gender" clearable style="width: 100%">
              <el-option label="Female" :value="0"></el-option>
              <el-option label="Male" :value="1"></el-option>
              <el-option label="Prefer not to say" :value="2"></el-option>
              <el-option label="Other" :value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Contact E-mail">
            <el-input v-model="formLabelAlign.contact_email"></el-input>
          </el-form-item>
          <el-form-item label="Phone">
            <el-input v-model="formLabelAlign.mobile"></el-input>
          </el-form-item>
          <el-form-item label="Training Language">
            <el-select v-model="formLabelAlign.training_language" multiple style="width: 100%;">
              <el-option v-for="(v,i) in languageList" :key="i" :label="v" :value="v">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Head Portrait">
            <el-upload class="avatar-uploader mr-3" action="no" :show-file-list="false" :http-request="uploadIMG"
              v-loading="loading">
              <img v-if="formLabelAlign.head_portrait" :src="formLabelAlign.head_portrait" alt="" />
              <img v-else src="../../assets/images/settings/camera.png" alt="" />
            </el-upload>
          </el-form-item>
        </el-form>
        <div class="d-flex justify-content-end mt-5 next_apply_btn">
          <el-button type="primary" :disabled="loading" @click="handlerSubmit">Submit</el-button>
        </div>
      </section>
    </el-dialog>

  </section>
</template>

<script>
  export default {
    data() {
      return {
        skillCourseInfo: {},
        courseTrainingList: [],
        keyword: '',
        status: '',
        trainerApply: false,
        trainerList: [],
        disabled: false,
        formLabelAlign: {
          trainer: '',
          mobile: '',
          contact_email: '',
          head_portrait: '',
          gender: '',
          training_language: '',
        },
        is_next: true,

        loading: false,
        languageList: [],

        countryList: [],
        organizationList: [],
        organizationData: { id: '', name: '' },
        countryData: { id: '', name: '' },

        page: 1,
        total: 0,
      };
    },

    components: {},

    mounted() {
      this.getSkillCourseInfo()
      this.getSkillCourseTraining()
      this.getAddress()
      this.getOrganizationList()
    },

    methods: {
      // 获取Skill课程详情
      getSkillCourseInfo() {
        this.$http.getSkillCourseInfo({ course_id: this.$route.query.id }).then((res) => {
          if (res.status == 200) {
            this.skillCourseInfo = res.data;
          }
        });
      },

      // 查看成为讲师进度
      checkApplyTrainer() {
        this.$http.checkApplyTrainer({ course_id: this.$route.query.id }).then((res) => {
          if (res.status == 200) {
            this.trainerList = res.data;
            this.disabled = this.trainerList.every(v => v.is_meet == 1)
          }
        });
      },

      // 获取国家列表
      async getAddress() {
        let { status, data } = await this.$http.getAddress({ level: 2 })
        if (status == 200) {
          this.countryList = data;
        }
      },

      // 获取组织列表
      async getOrganizationList() {
        let res = await this.$http.getOrganizationList({ type: 2 });
        if (res.status == 200) {
          this.organizationList = res.data;
        }
      },

      openApply() {
        this.checkApplyTrainer()
        this.trainerApply = true
        this.is_next = true
      },

      handlerStyle({ row, rowIndex }) {
        if (row.is_meet == 1) {
          return {
            backgroundColor: '#ecf5ff',
            color: '#50B5FF',
            border: '1px solid #50b5ff'
          }
        }
      },

      // 上传图片
      uploadIMG(params) {
        var formData = new FormData();
        formData.append("file", params.file);
        this.loading = true
        this.$http.uploadImage(formData).then((res) => {
          if (res.status == 200) {
            this.formLabelAlign.head_portrait = res.data[0];
          }
          setTimeout(() => { this.loading = false }, 1000);
        });
      },

      handleCurrentChange(page) {
        this.page = page;
        this.getSkillCourseTraining();
      },

      getMyTrainingSearch() {
        this.page = 1
        this.getSkillCourseTraining()
      },

      // 获取Skill课程的培训列表
      getSkillCourseTraining() {
        let data = { course_id: this.$route.query.id, keyword: this.keyword, status: this.status, page: this.page, per_page: 12 }
        this.$http.getSkillCourseTraining(data).then((res) => {
          if (res.status == 200) {
            this.courseTrainingList = res.data.data;
            this.total = res.data.total;
          }
        });
      },

      // 讲师信息
      getTrainerInfo() {
        this.$http.getTrainerInfo({ course_id: this.$route.query.id }).then((res) => {
          if (res.status == 200) {
            Object.keys(this.formLabelAlign).forEach((v) => {
              this.formLabelAlign[v] = res.data[v]
            });
            this.countryData = { id: res.data.country_id, name: res.data.country }
            this.organizationData = { id: res.data.organization_id, name: res.data.organization }
            this.languageList = res.data.course_language
          }
        });
      },

      handlerNext() {
        this.is_next = false
        this.getTrainerInfo()
      },

      // 成为讲师
      handlerSubmit() {
        this.formLabelAlign.organization = this.organizationData.name
        this.formLabelAlign.organization_id = this.organizationData.id
        this.formLabelAlign.country_id = this.countryData.id
        this.formLabelAlign.country = this.countryData.name
        this.$http.applyTrainer({ ...this.formLabelAlign, course_id: this.$route.query.id }).then((res) => {
          if (res.status == 200) {
            this.trainerApply = false
            this.getSkillCourseInfo()
          }
        });
      },

    },
  };
</script>
<style lang="scss" scoped>
  .jci_course_apply {
    .height_scroll {
      max-height: 700px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .top-banner {
      height: 230px;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 15px;
      position: relative;

      div {
        height: 230px;
        width: 100%;
        background: rgba($color: #999, $alpha: 0.3);
        display: flex;
        justify-content: center;
        border-radius: 5px;
        align-items: center;
        position: absolute;

        >h3 {
          color: #fff;
          font-size: 30px;
        }
      }
    }

    /deep/ .basic_info {
      

      .right {
        color: #999;
        flex: 1;

        ::v-deep p {
          margin: 0 !important;
          padding: 0 !important;
        }
      }

      .description {

        ul,
        ol {
          padding: 0;
          margin-bottom: 2px;
        }

        ul {
          list-style: disc;
        }
      }
    }

    ::v-deep .table-card .el-card__body {
      padding-top: 0px;
    }

    .top_container {
      padding: 10px 20px;
      border-bottom: 1px solid #eee;
    }

    .training_card {
      ::v-deep .el-card__body {
        padding: 0 !important;
      }

      .circle {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        margin-right: 5px;
      }
    }

    .training_container {
      padding: 20px;

      .training_content {
        height: 50px;
        border-bottom: 1px solid #eee;
        padding: 0 10px;
      }

      .training_title {
        line-height: 50px;
        border-bottom: 1px solid #eee;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        flex: 1;
      }

      .left {
        color: #999;
      }

      .bottom {
        padding-left: 10px;
        display: flex;
      }

      .one_line {
        flex: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

    }

    .circle_content {
      margin-top: 12px;

      .circle_item {
        display: flex;
        align-items: center;
        margin-left: 10px;
      }
    }

    .small_card {
      width: 300px;
      height: 120px;
      margin-right: 8px;
      background: #f8f8f8;
      border: 1px solid #eeeeee;
      border-radius: 5px;
      margin-bottom: 10px;
      cursor: pointer;

      h5 {
        font-size: 18px;
        font-family: Montserrat, Montserrat-Regular;
        font-weight: 400;
        color: #333333;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    /deep/ .el-table .cell {
      white-space: nowrap !important;
    }

    /deep/ .el-table::before {
      width: 0;
    }

    /deep/ .el-table__header-wrapper {
      margin-bottom: 10px;
    }

    /deep/ .el-table tr {
      background: #eee;
      margin-bottom: 10px;
    }

    /deep/ .el-table tbody tr td {
      padding-left: 30px !important;
    }

    /deep/ .el-table td.el-table__cell,
    .el-table th.el-table__cell.is-leaf {
      border: none;
    }

    /deep/ .el-table td.el-table__cell,
    .el-table th.el-table__cell.is-leaf {
      border: none;
    }

    /deep/ .el-table__body {
      border-collapse: separate;
      border-spacing: 0 10px;
      table-layout: auto !important;
    }

    /deep/ .el-table--enable-row-hover .el-table__body tr:hover>td {
      background-color: transparent !important;
    }

  }

  .to_my_training {
    cursor: pointer;
    border-bottom: 1px solid #EDBE38;
    color: #EDBE38;
    line-height: 18px;
  }

  .next_apply_btn {
    /* /deep/ button.el-button.el-button--primary.is-disabled {
      background-color: #ccc;
      border-color: #ccc;
    } */
  }

  .propose_course {
    /deep/ .el-form-item__label {
      line-height: 20px;
    }

    /deep/ .el-form--label-top .el-form-item__label {
      padding: 0
    }

    /deep/ .el-form-item__label::before {
      display: inline-block;
      content: '*';
      color: red;
    }

    .avatar-uploader {
      width: 90px;
      height: 90px;
      overflow: hidden;

      img {
        width: 90px;
        height: 90px;
      }
    }
  }
  @media (min-width: 600px) {
    .circle {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        margin-right: 5px;
      }
      .left {
        width: 200px;
      }
  }
  @media (max-width: 600px) {
    .search {
      ::v-deep .el-input, .el-select {
        width: 100% !important;
        margin: 5px 0;
      }
    }
    .left {
      width: 120px;
    }
    ::v-deep .el-tag{
      max-width: 220px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    ::v-deep .el-dialog{
      width: 100% !important;
      margin: 0 !important;
    }
    .circle_item{
      font-size: 10px;
      line-height: 10px;
      .circle{
        width: 10px;
        height: 10px ;
        border-radius: 50%;
        margin-right: 2px;
      }
    }
  }
</style>